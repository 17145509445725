import { BottomNavigation } from '@mui/material';
import FooterNavbarDesktop from './FooterNavbarDesktop';
import FooterNavbarMobile from './FooterNavbarMobile';
import '../../styles/FooterNavbar.scss';
import { bottomNavDesktopHeight, bottomNavMobileHeight } from '../../constants/const';

export const FooterNavbar = (props: any) => {
  return (
    <BottomNavigation
      className="bottomNavWrapper"
      sx={{ height: props.isMobile ? bottomNavMobileHeight : bottomNavDesktopHeight }}
    >
      {props.isMobile ? (
        <FooterNavbarMobile
          data={props.data}
        ></FooterNavbarMobile>
      ) : (
        <FooterNavbarDesktop
          isTablet={props.isTablet}
          data={props.data}
        ></FooterNavbarDesktop>
      )}
    </BottomNavigation>
  );
};
