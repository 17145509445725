import { Box, Grid } from '@mui/material';
import parse from 'html-react-parser';
import ImageButton from './core/ImageButton';
import '../styles/GridList.scss';

const GridList = (props: any) => {
  return (
    <Box
      className="container-gridlist"
      data-testid="gridlistContainer"
      sx={{
        backgroundColor: props.data.backgroundColor,
      }}
    >
      <div className="subContainer-gridlist">
        {props.data.preText ? <p>{parse(props.data.preText)}</p> : null}
        {props.data.title ? <h1>{parse(props.data.title)}</h1> : null}
        {props.data.mainText ? <p>{parse(props.data.mainText)}</p> : null}
        <Grid
          container
          sx={{ padding: (props.isMobile && !props.data.IconList) || props.isMobile ? '0px' : '100px 0px' }}
        >
          {props.data.IconList.map((iconData: any, index: number) => (
            <Grid
              key={index}
              style={{
                width: props.isMobile ? '100%' : props.data.iconWidth,
                margin: '0 auto',
              }}
            >
              {iconData.iconUrl ? (
                <img
                  src={iconData.iconUrl}
                  alt={iconData.id}
                  style={{
                    height:
                      props.isMobile && iconData.mobileIconHeight
                        ? iconData.mobileIconHeight
                        : iconData.iconHeight
                        ? iconData.iconHeight
                        : '120px',
                    verticalAlign: 'bottom',
                  }}
                ></img>
              ) : null}
              {iconData.title ? <h2>{parse(iconData.title)}</h2> : null}
              {iconData.iconText ? <p>{parse(iconData.iconText)} </p> : null}
            </Grid>
          ))}
        </Grid>
        {props.data.button ? (
          <Box
            className="buttonContainer-gridlist"
          >
            <ImageButton
              buttonColor={props.data.button.color}
              textColor={props.data.button.textColor}
              text={props.data.button.text}
              url={props.data.button.url}
              isMobile={props.isMobile}
              hasSpacing={false}
            ></ImageButton>
          </Box>
        ) : null}
        {props.data.footerText ? (
          <span style={{ margin: 'auto' }}>
            <small> {parse(props.data.footerText)}</small>
          </span>
        ) : null}
      </div>
    </Box>
  );
};

export default GridList;
