import { styled } from '@mui/material/styles';
import { Paper, Grid, Button, Link as Links } from '@mui/material';
import parse from 'html-react-parser';
import '../../styles/FooterNavbar.scss';

const GridItem = styled(Grid)(() => ({
  width: '100%',
  padding: '0px',
}));

const componentTranslation = (
  componentName: string,
  data: any,
) => {
  switch (componentName) {
    case 'Link':
      return (
        <Links href={data.ref} target={data.external ? "_blank" : "_parent"}>
          <Button className="styledFooterButton">{data.id}</Button>
        </Links>
      );
    case 'Image':
      return <img height="25px" width="225px" src={data.src} alt={data.alt}></img>;
    case 'ImageLink':
      return (
        <a href={data.url} target="_blank" rel="noopener noreferrer" className="imageLinkWrapper">
          <img height="25px" width={data.width || "25px"} src={data.src} alt={data.alt}></img>
          <span className="imageLinkText">{data.text}</span>
        </a>
      );
    case 'Text':
      return <span>&#169; {new Date().getFullYear()} {parse(data.text)}</span>;
    default:
      console.log('Component Not Found');
      break;
  }
};

//FooterNavbar is a little more complicated in that the mobile version has a very different format compared to desktop version
//Requires two separate items array to display the correct UI
const FooterNavbarMobile = (props: any) => {
  return (
    <GridItem item sx={{ justifyContent: 'center' }}>
      {[...props.data].map((item: any, index: number) => (
        <div key={index}>
          <Grid item key={index}>
            {item.id === 'FooterLogo' ? (
              <Paper className="itemWrapperMobile footerIconWrapper" key={index}>
                {item.items.map((itemData: any, subIndex: number) => (
                  <div key={subIndex}>
                    {componentTranslation(itemData.type, itemData)}
                  </div>
                ))}
              </Paper>
            ) : (
              item.items.map((itemData: any, subIndex: number) => (
                <Paper className="itemWrapperMobile" key={subIndex}>
                  {componentTranslation(itemData.type, itemData)}
                </Paper>
              ))
            )}
          </Grid>
        </div>
      ))}
    </GridItem>
  );
};



export default FooterNavbarMobile;
