import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export function IsMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
}

export function IsTablet() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.between('sm', 'lg'));
}

export const bottomNavMobileHeight = '325px';
export const bottomNavDesktopHeight = '150px';
